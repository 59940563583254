import { OrdersStatsSummary } from "src/app/core/interfaces/dispatch-stats.interface";
import { DispatchedOrder } from "../interfaces/dispatched-order.interface";
import { WorkboardOrder } from "../interfaces/workboard-order.interface";

export class DispatchDashboardStateModel {
  items: DispatchedOrder[];
  searchDate: string;
  loading: boolean;
  stats: OrdersStatsSummary;
  errorLoading: boolean;
  workboardOrders: WorkboardOrder[];
}