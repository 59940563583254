import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_URL } from "src/environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { Union } from "../../admin/unions/models/unions.model";

@Injectable({ providedIn: "root" })
export class DispatchDashboardService {
	
	private apiUrl: string
	
	constructor(
		private http: HttpClient,
		private env: EnvironmentService
	) {
		this.apiUrl = this.env.getValueTransferState(API_URL);
	}

  getLaborOrdersWithDispatch(date: string, unionId: number, maxResult: number = 20) {
		const params: HttpParams = new HttpParams({
			fromObject: {
				date: date,
				maxResult: maxResult,
				UnionId: unionId
			}
		});

		return this.http.get(`${this.apiUrl}/app/laborOrder/laborWithDispatch`, { params });
	}
	
	getUnions(): Observable<Union[]> {
		return this.http.get<Union[]>(`${this.apiUrl}/app/union/liveDispatchViewEnabled`);
	}
	
  getWorkboardOrders(date: string) {
		const params: HttpParams = new HttpParams({
			fromObject: {
				date: date
			}
		});

		return this.http.get(`${this.apiUrl}/app/laborOrder/workboard`, { params });
	}

}