export class LoadOrdersWithDispatch {
  public static readonly type = "[Dispatch Dashboard] load orders with dispatch";
  constructor(
    public date: string,
    public unionId: number
  ) {}
}

export class UpdateDispatchOrder {
  public static readonly type = "[Dispatch Dashboard] update dispatch order";
  constructor(
    public dispatch: any
  ) {}
}

export class ToggleDispatchOrder {
  public static readonly type = "[Dispatch Dashboard] toggle dispatch order";
  constructor(
    public orderId: string
  ) {}
}

export class LoadOrdersWorkboard {
  public static readonly type = "[Dispatch Dashboard] load orders workboard";
  constructor(
    public date: string
  ) {}
}