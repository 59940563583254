import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import moment from "moment";
import { tap } from "rxjs/operators";
import { DispatchedOrder } from "../interfaces/dispatched-order.interface";
import { DispatchDashboardService } from "../services/dispatch-dashboard.service";
import { LoadOrdersWithDispatch, LoadOrdersWorkboard, ToggleDispatchOrder, UpdateDispatchOrder } from "./dispatch-dashboard.actions";
import { DispatchDashboardStateModel } from "./dispatch-dashboard.model";

@Injectable()
@State<DispatchDashboardStateModel>({
  name: "dispatchDashboard",
  defaults: {
    items: [],
    searchDate: null,
    loading: false,
    stats: null,
    errorLoading: false,
    workboardOrders: []
  }
})
export class DispatchDashboardState {
  constructor(
    private dashboardService: DispatchDashboardService
  ) {}

  @Selector() static orders(state: DispatchDashboardStateModel) { return state.items; }
  @Selector() static loading(state: DispatchDashboardStateModel) { return state.loading; }
  @Selector() static stats(state: DispatchDashboardStateModel) { return state.stats; }
  @Selector() static errorLoading(state: DispatchDashboardStateModel) { return state.errorLoading; }
  @Selector() static workboardOrders(state: DispatchDashboardStateModel) { return state.workboardOrders; }

  mapOrder(order) {
    const date = new Date(order.startDate);
    let numberOfWorkers = 0;
    let numberOfAssignments = 0;

    const mapJobList = jobList => {
      return jobList.map(
        (item) => {      
          numberOfWorkers += item.numberOfWorkers;
          numberOfAssignments += item.jobAssignments.length;
          return {
            id: item.id,
            name: item.name,
            numberOfWorkers: item.numberOfWorkers,
            numberOfAssignments: item.jobAssignments.length,
            jobAssignments: item.jobAssignments.map(
              (jobA) => {
                return {
                  ...jobA,
                  creationTime: new Date(jobA.creationTime + '+0000'),
                  hasBeenReplaced: jobA.replacements?.length > 0 ? true : false,
                  replacements: jobA.replacements.map(
                    (replace,i) => {
                      return {
                        ...replace,
                        creationTime: new Date(replace.creationTime + '+0000'),
                        hasBeenReplaced: i == jobA.replacements?.length - 1 ? false : true
                      }
                    }
                  )
                }
              }
            )
          };
        }
      );
    };

    const jobDetails = order.jobDetails.map(job => {
      const jobRequests = mapJobList(job.jobList);
      return {
        ...job,
        numberOfWorkers: jobRequests
          .map(jr => jr.numberOfWorkers)
          .reduce((acc, value) => {
            return acc + value;
          }),
        numberOfAssignments: jobRequests
          .map(jr => jr.numberOfAssignments)
          .reduce((acc, value) => {
            return acc + value;
          }),
        jobRequests
      };
    });

    const isAssignmentFull = () => {
      let result;

      jobDetails.some(jd => {
        result = jd.numberOfAssignments >= jd.numberOfWorkers;
        if (result === false) return true; // Break the loop if any of the jobRequests is not fully assigned
      });

      return result;
    };

    return {
      id: order.id,
      name: order.name,
      employer: order.employer,
      terminal: order.terminal,
      numberOfAssignments: numberOfAssignments,
      numberOfWorkers: numberOfWorkers,
      isAssignmentFull: isAssignmentFull(),
      date: moment(date).format("MMM, Do YYYY"),
      startDate: order.startDate,
      time: moment(date).format("HH:mm"),
      vessel: order.vessel,
      moves: order.moves,
      comments: order.comments,
      sortingOrder: order.sortingOrder,
      multipleStartTime: order.multipleStartTime,
      terminalName: order.terminalName,
      cargoType: order.cargoType,
      jobDetails,
      expanded: true
    };
  }

  @Action(LoadOrdersWithDispatch)
  loadOrders(ctx: StateContext<DispatchDashboardStateModel>, action: LoadOrdersWithDispatch) {
    ctx.patchState({
      items: [],
      loading: true,
      errorLoading: false,
      searchDate: action.date
    });

    return this.dashboardService.getLaborOrdersWithDispatch(action.date, action.unionId).pipe(
      tap(
        (response: any) => {
          ctx.patchState({
            items: response.realtimeDashboardLaborOrder.map(item => {
              return this.mapOrder(item);
            }),
            stats: response.realtimeDashboardLaborOrderHeader,
            loading: false,
          });
        },
        error => {
          ctx.patchState({
            loading: false,
            errorLoading: true,
            searchDate: null
          });
        }
      )
    );
  }

  @Action(UpdateDispatchOrder)
  update(ctx: StateContext<DispatchDashboardStateModel>, { dispatch }) {
    const state = ctx.getState();

    const foundOrder = state.items.find(item => {
      let isMatch = false;
      item.jobDetails.forEach(jobDetail => {
        if (isMatch) return;
        isMatch = jobDetail.id === dispatch.id;
      });
      return isMatch;
    });

    let mappedOrder;
    if (foundOrder) {
      let originalFoundOrder = JSON.stringify(foundOrder);
      let newFoundOrder = JSON.parse(originalFoundOrder);

      newFoundOrder.jobDetails.forEach(jobDetail => {
        if (jobDetail.id === dispatch.id) {
          jobDetail.jobList = dispatch.jobRequests;
        }
      });
      mappedOrder = this.mapOrder(newFoundOrder);

      ctx.setState(
        patch({
          items: updateItem(item => {
            return item.id === foundOrder.id;
          }, mappedOrder)
        })
      );
    }
  }

  @Action(ToggleDispatchOrder)
  toggle(ctx: StateContext<DispatchDashboardStateModel>, { orderId }) {
    const state: DispatchDashboardStateModel = ctx.getState();
    const order: DispatchedOrder = state.items.find((o) => { return o.id === orderId });
    if (order) {
      const newOrder: DispatchedOrder = Object.assign({}, order);
      newOrder.expanded = !newOrder.expanded;
      ctx.setState(
        patch({
          items: updateItem((item) => {
            return item.id === orderId
          }, newOrder)
        })
      );
    }
  }

  @Action(LoadOrdersWorkboard)
  loadOrdersWorkboard(ctx: StateContext<DispatchDashboardStateModel>, action: LoadOrdersWorkboard) {
    ctx.patchState({
      workboardOrders: [],
      loading: true,
      errorLoading: false,
      searchDate: action.date
    });

    return this.dashboardService.getWorkboardOrders(action.date).pipe(
      tap(
        (response: any) => {
          ctx.patchState({
            workboardOrders: response,
            loading: false,
          });
        },
        error => {
          ctx.patchState({
            loading: false,
            errorLoading: true,
            searchDate: null
          });
        }
      )
    );
  }
}
